<template>
  <b-row class="card-pricing-item ml-n2 mr-n2 pl-50 pr-50 pt-50 pb-50">
    <b-col class="d-flex align-items-center">
      <b-breadcrumb class="p-0">
        <b-breadcrumb-item :to="{ name: 'menu', params: { id: offerTree.id } }">
          {{ offerTree.name }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active>
          {{ offerTree.categories[0].name }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </b-col>
    <b-col cols="auto">
      <div class="d-flex align-items-center">
        <validation-observer ref="vo">
          <offer
            :offer-id="offer.id"
            :offer-data="offerData"
            @updateValue="$emit('updateValue', $event)"
            @save="onSave"
          />
        </validation-observer>
        <div class="d-flex ml-1">
          <feather-icon
            class="card-pricing-item__delete"
            role="button"
            size="20"
            icon="XCircleIcon"
            @click="$emit('delete')"
          />
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BBreadcrumb,
  BBreadcrumbItem,
} from 'bootstrap-vue'
import Offer from '@/components/Offer.vue'
import { mapGetters, mapActions } from 'vuex'
import { ValidationObserver } from 'vee-validate'

import mixinRequests from '@/mixins/requests'
import mixinOffer from '@/mixins/offer'

export default {
  name: 'CardPricingItem',
  components: {
    BRow,
    BCol,
    BBreadcrumb,
    BBreadcrumbItem,
    Offer,
    ValidationObserver,
  },
  mixins: [
    mixinRequests,
    mixinOffer,
  ],
  props: {
    offerTree: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      menus: 'menuManagement/menus',
    }),
    offer() {
      return this.offerTree.categories[0].items[0]
    },
    offerData() {
      return {
        measurementUnit: this.offer.measurementUnit,
        amount: this.offer.amount,
        price: this.offer.price,
      }
    },
  },
  methods: {
    ...mapActions({
      updateMenu: 'menuManagement/updateMenu',
    }),
    async onSave() {
      await this.$nextTick()

      const isValid = await this.$refs.vo.validate()
      if (!isValid) {
        return
      }

      // eslint-disable-next-line no-undef
      const categories = structuredClone(this.menus.find(i => i.id === this.offerTree.id).categories)
        .map(i => (i.id === this.offerTree.categories[0].id
          ? {
            ...i,
            items: i.items.map(j => (j.id === this.offer.id
              ? {
                ...j,
                measurementUnit: this.offerData.measurementUnit,
                amount: this.offerData.amount,
                price: this.offerData.price,
              }
              : j
            )),
          }
          : i
        ))
      this.updateMenu([this.offerTree.id, 'categories', categories])

      this.requests.push('updateOffer')
    },
  },
}
</script>

<style lang="sass">
@import '@core/scss/base/bootstrap-extended/_variables.scss'

.card-pricing-item
  &:hover
    background-color: $body-bg
    .card-pricing-item__delete
      opacity: 1
  &__delete
    opacity: 0
</style>
