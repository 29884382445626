<template>
  <b-card class="card-suggestions">
    <b-row>
      <b-col class="d-flex align-items-center">
        <b-card-title class="mb-0">
          {{ $t('cardSuggestionsTitle') }}
        </b-card-title>
        <feather-icon
          icon="InfoIcon"
          role="button"
          size="20"
          class="ml-50 text-primary"
          @click.stop="setIsInfo([page, 'suggestions'])"
        />
      </b-col>
      <b-col
        v-if="!items.length"
        cols="auto"
      >
        <b-button
          id="suggestions-button"
          variant="outline-primary"
          size="sm"
          @click="onClickSuggest"
        >
          {{ $t('cardSuggestionsButtonName') }}
        </b-button>
        <b-tooltip
          v-if="!isSubscriptionPremium"
          target="suggestions-button"
        >
          <div class="pt-50 pb-50">
            <span>{{ $t('tooltipSuggestions') }}</span>
            <b-button
              block
              size="sm"
              class="mt-50"
              variant="primary"
              @click="$router.push({ name: 'billing' })"
            >
              {{ isSubscriptionInactive ? $t('Start Free Trial') : $t('Upgrade') }}
            </b-button>
          </div>
        </b-tooltip>
      </b-col>
    </b-row>
    <b-collapse v-model="isInfo[page].suggestions">
      <small class="d-block mt-2">
        {{ $t('infoSuggestions') }}
      </small>
    </b-collapse>
    <template v-if="items.length">
      <b-form-group
        class="mt-2"
        :label="$t('cardSuggestionsNameLabel')"
      >
        <validation-observer ref="name">
          <validation-provider
            #default="{ errors, failedRules }"
            name="suggestions_name"
            :rules="`max:${textLimit}`"
          >
            <b-form-input
              v-b-tooltip.hover.top="$t('cardSuggestionsNameTooltip')"
              :value="name"
              :placeholder="$t('cardSuggestionsNamePlaceholder')"
              :state="errors.length ? false : null"
              @input="$emit('inputName', $event)"
              @blur="onBlurName"
            />
            <small
              v-if="errors.length"
              class="text-danger"
            >
              <template v-if="failedRules.max">
                {{ $t('validationErrorMaxSuggestionsName', { slot: textLimit }) }}
              </template>
              <template v-else>{{ errors[0] }}</template>
            </small>
            <div
              v-else
              class="text-right"
            >
              <small>{{ name.length }} / {{ textLimit }}</small>
            </div>
          </validation-provider>
        </validation-observer>
      </b-form-group>
      <b-row class="mt-1">
        <b-col>
          <b-form-group
            :label="$t('cardSuggestionsItemsLabel')"
            class="mb-0"
          />
        </b-col>
      </b-row>
      <draggable
        class="mb-2 ml-n50 mr-n50"
        :value="items"
        @input="$emit('inputItems', $event)"
        @end="$emit('saveItems')"
      >
        <b-row
          v-for="item in items"
          :key="item.id"
          class="card-suggestions__added-item p-50"
        >
          <b-col>
            <div class="d-flex align-items-center">
              <icon-move-vertical class="mr-75" />
              <b-link
                :to="{
                  name: item.type === 'MenuItem' ? 'menu-item' : 'combination-menu-item',
                  params: { id: item.id },
                }"
              >
                {{ item.name }}
              </b-link>
            </div>
          </b-col>
          <b-col
            cols="auto"
            class="d-flex align-items-center"
          >
            <feather-icon
              size="20"
              role="button"
              icon="XCircleIcon"
              class="card-suggestions__remove"
              @click="onClickDeleteItem(item.id)"
            />
          </b-col>
        </b-row>
      </draggable>
    </template>
    <treeselect
      v-if="isTreeselect"
      :value="items"
      :options="menuItems"
      :placeholder="$t('Select from existing items')"
      :normalizer="(i) => ({ label: i.name, isDisabled: i.id === $route.params.id })"
      :multiple="true"
      :clearable="false"
      :auto-focus="true"
      :open-on-focus="true"
      value-format="object"
      @input="$emit('inputItems', $event)"
      @close="onCloseTreeselect"
    />
    <template v-else-if="items.length">
      <b-button
        id="suggestions-button2"
        variant="outline-primary"
        size="sm"
        @click="onClickSuggest"
      >
        {{ $t('cardSuggestionsButtonName') }}
      </b-button>
      <b-tooltip
        v-if="!isSubscriptionPremium"
        target="suggestions-button2"
      >
        <div class="pt-50 pb-50">
          <span>{{ $t('tooltipSuggestions') }}</span>
          <b-button
            block
            size="sm"
            class="mt-50"
            variant="primary"
            @click="$router.push({ name: 'billing' })"
          >
            {{ isSubscriptionInactive ? $t('Start Free Trial') : $t('Upgrade') }}
          </b-button>
        </div>
      </b-tooltip>
    </template>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BLink,
  BCollapse,
  VBTooltip,
  BTooltip,
  BButton,
} from 'bootstrap-vue'
import Treeselect from '@riophae/vue-treeselect'
import { mapGetters, mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { max } from '@validations'
import draggable from 'vuedraggable'
import IconMoveVertical from '@/components/icon/IconMoveVertical.vue'

export default {
  name: 'CardSuggestions',
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BLink,
    BCollapse,
    Treeselect,
    BTooltip,
    BButton,
    draggable,
    IconMoveVertical,

    // validations
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    name: {
      type: String,
      required: true,
      default: '',
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    page: {
      type: String,
      required: true,
      default: '',
    },
    textLimit: {
      type: Number,
      required: true,
      default: 100,
    },
  },
  data() {
    return {
      isTreeselect: false,

      // validation rules
      max,
    }
  },
  computed: {
    ...mapGetters({
      isInfo: 'main/isInfo',
      menuItems: 'menuManagement/menuItems',
      subscription: 'billing/subscription',
      isSubscriptionInactive: 'billing/isSubscriptionInactive',
      isSubscriptionPremium: 'billing/isSubscriptionPremium',
    }),
  },
  methods: {
    ...mapActions({
      setIsInfo: 'main/setIsInfo',
    }),
    async onBlurName() {
      const isValid = await this.$refs.name.validate()
      if (isValid) {
        this.$emit('saveName')
      }
    },
    onClickDeleteItem(id) {
      this.$emit('inputItems', this.items.filter(i => i.id !== id))
      this.$emit('saveItems')
    },
    onClickSuggest() {
      if (!this.isSubscriptionPremium) {
        return
      }
      this.isTreeselect = true
    },
    onCloseTreeselect() {
      this.$emit('saveItems')
      this.isTreeselect = false
    },
  },
}
</script>

<style lang="sass">
@import '@/assets/sass/treeselect.sass'

.card-suggestions
  &__added-item
    cursor: row-resize
    .icon-move-vertical
      opacity: 0
      transform: scale(0.85)
    &:hover
      background-color: $body-bg
      .icon-move-vertical
        opacity: 1
      .card-suggestions__remove
        opacity: 1
  &__remove
      opacity: 0
</style>
