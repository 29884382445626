import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      subscription: 'billing/subscription',
      isSubscriptionInactive: 'billing/isSubscriptionInactive',
    }),
    isAddExtraDisabled() {
      return this.isSubscriptionInactive || (this.subscription && this.subscription.productDetails.name !== 'Premium')
    },
  },
}
