<template>
  <div
    :class="[
      'item-preparation',
      { 'item-preparation_active': isDropdownShown },
    ]"
    @click="onClickRootElement"
  >
    <b-dropdown
      ref="dropdown"
      no-caret
      lazy
      variant="flat"
      class="w-100"
      @shown="onShownDropdown"
      @hidden="onHiddenDropdown"
    >
      <template #button-content>
        <feather-icon
          icon="ClockIcon"
          size="18"
        />
        <span class="ml-50 mr-2 font-weight-bolder">{{ $t('Preparation Time') }}</span>
        <template v-if="value.time">
          <span>{{ value.time }}{{ $t('min') }}</span>
          <feather-icon
            v-b-tooltip.hover.top="value.isInMenus ? $t('Show in menus') : $t('Hide in Menus')"
            :icon="value.isInMenus ? 'EyeIcon' : 'EyeOffIcon'"
            class="ml-50 mr-50"
          />
          <feather-icon
            icon="XIcon"
            role="button"
            class="ml-auto"
            size="18"
            @click.stop="onClickClear"
          />
        </template>
        <small
          v-else
          class="text-secondary"
        >{{ $t('empty') }}</small>
      </template>
      <div @click.stop>
        <h5 class="mb-1">
          {{ $t('Preparation Time') }}
        </h5>
        <validation-observer ref="time">
          <validation-provider
            #default="{ errors, failedRules }"
            name="preparation_time"
            :rules="`integer|max:${textLimit}`"
          >
            <b-input-group
              :append="$t('min')"
              size="sm"
            >
              <b-form-input
                ref="input"
                :value="value.time"
                placeholder="90"
                type="number"
                :state="errors.length ? false : null"
                @input="onInputTime"
                @blur="onBlurTime"
              />
            </b-input-group>
            <small class="text-danger">
              <template v-if="failedRules.integer">{{ $t('validationErrorIntegerPreparationTime') }}</template>
              <template v-else-if="failedRules.max">{{ $t('validationErrorMaxPreparationTime', { slot: textLimit }) }}</template>
              <template v-else>{{ errors[0] }}</template>
            </small>
          </validation-provider>
        </validation-observer>
        <b-form-checkbox
          v-b-tooltip.hover.bottom="$t('cardPreparationTimeTooltip')"
          :checked="value.isInMenus"
          class="mt-1 d-inline-flex"
          @input="onInputIsInMenus"
          @change="onChangeIsInMenus"
        >
          <small>{{ $t('Show in menus') }}</small>
        </b-form-checkbox>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import {
  BDropdown,
  BInputGroup,
  BFormInput,
  BFormCheckbox,
  VBTooltip,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  integer,
  max,
} from '@validations'

export default {
  name: 'ItemPreparation',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BDropdown,
    BInputGroup,
    BFormInput,
    BFormCheckbox,

    // validations
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({
        time: '',
        isInMenus: false,
      }),
    },
  },
  data() {
    return {
      textLimit: 3,
      isDropdownShown: false,

      // validation rules
      integer,
      max,
    }
  },
  methods: {
    onInputTime(event) {
      this.$emit('input', { ...this.value, time: event })
    },
    async onBlurTime() {
      const isValid = await this.$refs.time.validate()
      if (isValid) {
        this.$emit('savePreparationTime')
      }
    },

    onInputIsInMenus(event) {
      this.$emit('input', { ...this.value, isInMenus: event })
    },
    onChangeIsInMenus() {
      this.$emit('savePreparationIsInMenus')
    },

    onClickClear() {
      this.$emit('input', { ...this.value, time: '', isInMenus: false })
      this.$emit('savePreparationTime')
      this.$emit('savePreparationIsInMenus')
    },

    onShownDropdown() {
      this.$refs.input.focus()
      this.isDropdownShown = true
    },
    onHiddenDropdown() {
      this.isDropdownShown = false
    },

    onClickRootElement() {
      const refDropdown = this.$refs.dropdown
      if (refDropdown.visible) {
        refDropdown.hide()
      } else {
        refDropdown.show()
      }
    },
  },
}
</script>

<style lang="sass">
@import '@core/scss/base/bootstrap-extended/_variables.scss'

.item-preparation
  cursor: pointer
  padding: 1rem 1.5rem
  &_active,
  &:hover
    background-color: $body-bg
  .dropdown-toggle
    display: flex
    align-items: center
    border: none
    border-radius: 0
    padding: 3px 0
  .dropdown-menu
    width: 250px
    padding: 1rem
    background-color: $body-bg
    border-color: $gray-300
    cursor: auto
  .form-control
    &.is-invalid
      background-image: none
      & + .input-group-append
        .input-group-text
          border-color: $red
</style>
