<template>
  <div class="item-group">
    <div
      class="item-group__header"
      @click="onClickHeader"
    >
      <h5 class="mb-0 mr-50">
        {{ title }}
      </h5>
      <slot
        name="after-title"
        :isOpened="isOpened"
      />
      <feather-icon
        icon="ChevronDownIcon"
        size="18"
        :class="['item-group__arrow ml-auto', isOpened ? null : 'rotate-180']"
      />
    </div>
    <b-collapse
      v-model="isOpened"
      class="item-group__body"
    >
      <div class="pt-1 pb-1 pl-3 pr-3">
        <slot />
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'

export default {
  name: 'ItemGroup',
  components: {
    BCollapse,
  },
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      isOpened: true,
    }
  },
  methods: {
    onClickHeader() {
      this.isOpened = !this.isOpened
    },
  },
}
</script>

<style lang="sass">
@import '@core/scss/base/bootstrap-extended/_variables.scss'

.item-group
  &__header
    display: flex
    align-items: center
    cursor: pointer
    border-top: 1px solid $border-color
    transition: background-color 0.15s ease
    padding: 1rem 1.5rem
    &:hover
      background-color: $body-bg
  &__arrow
    transition: transform 0.25s ease
</style>
