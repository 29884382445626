<template>
  <b-overlay
    :show="isFetching || isSaving"
    spinner-variant="primary"
  >
    <b-card>
      <b-row>
        <b-col class="d-flex align-items-center">
          <b-card-title class="mb-0">
            {{ $t('cardPricingTitle') }}
          </b-card-title>
        </b-col>
        <b-col
          v-if="!addTo && !value.length"
          cols="auto"
        >
          <b-button
            id="btn-add-to-menu"
            size="sm"
            variant="outline-primary"
            @click="onClickAddToMenu"
          >
            {{ $t('Add to Menu') }}
          </b-button>
          <tooltip-create-menu
            target="btn-add-to-menu"
            triggers="focus"
          />
        </b-col>
      </b-row>
      <div
        v-if="value.length"
        class="mt-2"
      >
        <card-pricing-item
          v-for="offerTree in value"
          :key="offerTree.categories[0].items[0].id"
          :offer-tree="offerTree"
          @updateValue="onUpdateValue"
          @delete="onDelete(offerTree)"
        />
      </div>
      <div
        v-if="addTo"
        class="d-flex align-items-center mt-2"
      >
        <input-select
          ref="input-select"
          :class="['flex-grow-1', { 'input-select_hide-input': !!addTo.menu && !!addTo.category }]"
          label="name"
          :options="!addTo.menu ? menus : categoriesToAdd"
          :placeholder="selectPlaceholder"
          :autofocus="true"
          :disabled="!!addTo.menu && !!addTo.category"
          :clearable="!!addTo.menu"
          :clear-on-select="true"
          :input-group-merge="true"
          :keep-open="!addTo.menu"
          :filter-selection="true"
          :exact="true"
          @select="onSelect"
          @clear="addTo = {}"
        >
          <template
            v-if="addTo.menu"
            #prepend
          >
            <b-input-group-prepend is-text>
              <span class="font-weight-bold">{{ addTo.menu.name }}</span>
              <sup
                v-if="!addTo.menu.id"
                class="ml-25 text-danger"
              >{{ $t('New') }}</sup>
              <feather-icon
                icon="ChevronRightIcon"
                :class="['ml-50', { 'mr-50': addTo.category }]"
              />
              <template v-if="addTo.category">
                <span class="font-weight-bold">{{ addTo.category.name }}</span>
                <sup
                  v-if="!addTo.category.id"
                  class="ml-25 text-danger"
                >{{ $t('New') }}</sup>
              </template>
            </b-input-group-prepend>
          </template>
        </input-select>
        <b-button
          class="ml-1 mr-1"
          :disabled="!addTo.menu || !addTo.category"
          variant="primary"
          size="sm"
          @click="onClickSave"
        >
          {{ $t('Save') }}
        </b-button>
        <b-button
          variant="outline-primary"
          size="sm"
          @click="addTo = null"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
      <b-button
        v-else-if="!addTo && value.length"
        id="btn-add-to-menu2"
        class="mt-2"
        size="sm"
        variant="outline-primary"
        @click="onClickAddToMenu"
      >
        {{ $t('Add to Menu') }}
      </b-button>
      <tooltip-create-menu
        target="btn-add-to-menu2"
        triggers="focus"
      />
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BCardTitle,
  BRow,
  BCol,
  BInputGroupPrepend,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'

import CardPricingItem from '@/components/CardPricingItem.vue'
import InputSelect from '@/components/InputSelect.vue'
import TooltipCreateMenu from '@/components/tooltip/TooltipCreateMenu.vue'

import mixinRequests from '@/mixins/requests'
import mixinFormatter from '@/mixins/formatter'
import mixinTooltipCreateMenu from '@/mixins/tooltip/tooltip-create-menu'
import mixinGqlCategory from '@/mixins/gql/category'
import mixinOffer from '@/mixins/offer'

import UPDATE_MENU from '@/gql/mutation/menu/updateMenu.gql'

export default {
  name: 'CardPricing',
  components: {
    BOverlay,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BInputGroupPrepend,
    BButton,

    CardPricingItem,
    InputSelect,
    TooltipCreateMenu,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [
    mixinRequests,
    mixinFormatter,
    mixinTooltipCreateMenu,
    mixinGqlCategory,
    mixinOffer,
  ],
  props: {
    value: {
      type: Array,
      required: true,
      default: () => [],
    },
    isFetching: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    return {
      addTo: null,
      isSaving: false,
    }
  },
  computed: {
    ...mapGetters({
      menus: 'menuManagement/menus',
    }),
    selectPlaceholder() {
      if (this.addTo && this.addTo.category) {
        return ''
      }

      return `${!this.addTo.menu ? this.$t('Menu') : this.$t('Category')}: ${this.$t('Search or Create')}`
    },
    categoriesToAdd() {
      if (!this.addTo || !this.addTo.menu) {
        return []
      }

      const menu = this.menus.find(i => i.id === this.addTo.menu.id)
      if (!menu) {
        return []
      }

      return menu.categories
    },
  },
  methods: {
    ...mapActions({
      createMenu: 'menuManagement/createMenu',
      getMenus: 'menuManagement/getMenus',
      getMenuItems: 'menuManagement/getMenuItems',
      updateMenu: 'menuManagement/updateMenu',
    }),
    onClickAddToMenu() {
      if (this.isCreateMenuDisabledWithoutPlan || this.isCreateMenuDisabledWithPlan) {
        return
      }

      this.addTo = {}
    },
    onSelect(value) {
      const type = !this.addTo.menu ? 'menu' : 'category'

      this.addTo = { ...this.addTo, [type]: value }

      if (type === 'menu') {
        this.$refs['input-select'].focus()
      }
    },
    async onClickSave() {
      this.isSaving = true

      const createOfferResponse = await this.createOffer(this.$route.params.id)[0]

      let { menu, category } = this.addTo
      if (!menu.id) {
        menu = await this.createMenu(menu.name)
      }
      if (!category.id) {
        const response = await this.createCategory(category.name)[0]
        category = response.data.createCategory
      }

      await this.updateCategory(
        category.id,
        {
          items: [
            ...category.items.map(i => i.id),
            createOfferResponse.data.createOffer.id,
          ],
        },
      )[0]

      await this.$apollo.mutate({
        mutation: UPDATE_MENU,
        variables: {
          updateMenuId: menu.id,
          categories: [
            ...new Set([
              ...menu.categories.map(i => i.id),
              category.id,
            ]),
          ],
        },
      })

      await Promise.all([
        this.getMenus(),
        this.getMenuItems(),
      ])

      this.$emit('reset')

      this.addTo = null
      this.isSaving = false
    },
    onUpdateValue([offerId, fieldName, value]) {
      const toEmitValue = this.value.map(i => {
        if (i.categories[0].items[0].id === offerId) {
          return {
            ...i,
            categories: [
              {
                ...i.categories[0],
                items: [
                  {
                    ...i.categories[0].items[0],
                    [fieldName]: value,
                  },
                ],
              },
            ],
          }
        }
        return i
      })

      this.$emit('input', toEmitValue)
    },
    onDelete(offerTree) {
      const menu = this.menus.find(i => i.id === offerTree.id)
      const categoryId = offerTree.categories[0].id
      const offerId = offerTree.categories[0].items[0].id

      const categories = menu.categories.map(i => {
        if (i.id === categoryId) {
          return {
            ...i,
            content: i.content.filter(j => j.id !== offerId),
            items: i.items.filter(j => j.id !== offerId),
          }
        }
        return i
      })

      this.requests.push(
        () => this.updateCategory(
          categoryId,
          {
            items: categories.find(i => i.id === categoryId).items.map(i => i.id),
          },
        ),
        () => this.deleteOffer(offerId),
      )

      this.updateMenu([offerTree.id, 'categories', categories])

      this.$emit('input', this.value.filter(i => i.categories[0].items[0].id !== offerId))
    },
  },
}
</script>
