<template>
  <div>
    <b-form-group
      v-for="(item, itemIndex) in itemsTranslationsToRender"
      :key="item.id"
      :label="itemIndex === 0 ? label : null"
    >
      <b-input-group class="input-group-merge">
        <b-form-input
          v-model="item.name"
          :disabled="isSubscriptionInactiveOrBasic"
          @blur="onBlurItemTranslation(item)"
        />
        <b-input-group-append
          v-if="isSubscriptionPremium"
          is-text
        >
          <icon-translate
            v-b-tooltip.hover.top="$t('Auto Translate')"
            class="size-18"
            role="button"
            @click.native="onClickTranslateItem(item)"
          />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  VBTooltip,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import IconTranslate from '@/components/icon/IconTranslate.vue'

import mixinRequests from '@/mixins/requests'
import mixinGoogleTranslation from '@/mixins/google-translation'

export default {
  name: 'ItemBadgesTagsTranslations',
  components: {
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    IconTranslate,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [
    mixinRequests,
    mixinGoogleTranslation,
  ],
  props: {
    translationActive: {
      type: String,
      required: true,
      default: '',
    },
    value: {
      type: Array,
      required: true,
      default: () => [],
    },
    label: {
      type: String,
      required: true,
      default: '',
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    itemsTranslations: {
      type: Array,
      required: true,
      default: () => [],
    },
    setItemsTranslations: {
      type: Function,
      required: true,
      default: () => null,
    },
    updateItemTranslation: {
      type: Function,
      required: true,
      default: () => null,
    },
  },
  data() {
    return {
      itemsTranslationsToRender: [],
    }
  },
  computed: {
    ...mapGetters({
      isSubscriptionInactiveOrBasic: 'billing/isSubscriptionInactiveOrBasic',
      isSubscriptionPremium: 'billing/isSubscriptionPremium',
    }),
  },
  watch: {
    translationActive() {
      this.setItemsTranslationsToRender()
    },
  },
  created() {
    this.setItemsTranslationsToRender()
  },
  methods: {
    setItemsTranslationsToRender() {
      // eslint-disable-next-line no-undef
      const itemsTranslations = structuredClone(this.itemsTranslations)

      this.itemsTranslationsToRender = this.value
        .map(i => itemsTranslations.find(j => j.originalId === i.id && j.language === this.translationActive))
    },
    saveItemsTranslations(itemsTranslationsToSave) {
      // eslint-disable-next-line no-undef
      let itemsTranslations = structuredClone(this.itemsTranslations)
      itemsTranslationsToSave.forEach(i => {
        itemsTranslations = itemsTranslations.map(j => (j.id === i.id ? { ...j, name: i.name } : j))
      })
      this.setItemsTranslations(itemsTranslations)

      const updateItemsTranslations = () => itemsTranslationsToSave
        .map(i => this.updateItemTranslation(i.language, i.originalId, i.name)[0])
      this.requests.push(updateItemsTranslations)
    },

    async onClickTranslateItem(itemTranslation) {
      const response = await this.getGoogleTranslation([
        this.value.find(i => i.id === itemTranslation.originalId).name,
      ])

      this.itemsTranslationsToRender = this.itemsTranslationsToRender
        .map(i => (i.id === itemTranslation.id ? { ...itemTranslation, name: response[0] } : i))
      this.saveItemsTranslations([{ ...itemTranslation, name: response[0] }])
    },
    onBlurItemTranslation(item) {
      this.saveItemsTranslations([item])
    },
  },
}
</script>
