import { v4 as uuidv4 } from 'uuid'
import mixinTooltipAddExtra from '@/mixins/tooltip/tooltip-add-extra'

export default {
  mixins: [
    mixinTooltipAddExtra,
  ],
  data() {
    return {
      extras: [],
      isExtraPosting: false,
      isExtrasValid: true,
      textLimitExtra: 100,
    }
  },
  computed: {
    optionsTreeselectExtras() {
      return this.menuItems
        .filter(i => i.id !== this.$route.params.id)
        .map(i => ({
          ...i,
          label: i.name,
          isDisabled: this.isAddExtraDisabled,
        }))
    },
  },
  methods: {
    getExtraMenuItems(extraId) {
      const extra = this.extras.find(i => i.id === extraId)
      if (!extra) {
        return []
      }

      return extra.items.filter(i => i.type === 'MenuItem' || i.type === 'CombinationMenuItem')
    },

    setExtras(extras) {
      // eslint-disable-next-line no-undef
      this.extras = structuredClone(extras).map(i => ({
        ...i,
        sameAddPriceValue: this.getFormattedPrice(i.sameAddPrice && i.items.length ? i.items[0].addPrice : null),
        items: i.items
          .map(j => {
            if (j.type === 'SimpleItem') {
              return { ...j, addPrice: this.getFormattedPrice(j.addPrice) }
            }

            const item = this.menuItems.find(k => k.id === j.id)
            if (item) {
              return { ...j, addPrice: this.getFormattedPrice(j.addPrice) }
            }

            return null
          })
          .filter(j => !!j),
      }))

      if (
        (
          this.extras.length
          && this.$refs['card-extras']
          && !this.$refs['card-extras'].$el.getElementsByClassName('card-content collapse')[0].classList.contains('show')
        ) || (
          !this.extras.length
          && this.$refs['card-extras']
          && this.$refs['card-extras'].$el.getElementsByClassName('card-content collapse')[0].classList.contains('show')
        )
      ) {
        this.$refs['card-extras'].$el.getElementsByClassName('card-header')[0].click()
      }
    },

    async validateExtras(refName, showNotification) {
      this.isExtrasValid = await this.$refs[refName].validate()
      if (!this.isExtrasValid && showNotification) {
        this.showNotificationToFixValidation()
      }
    },
    patchExtras() {
      const extras = this.extras.map(i => ({
        id: i.id,
        name: i.name,
        sameAddPrice: i.sameAddPrice,
        // eslint-disable-next-line no-undef
        items: structuredClone(i.items).map(j => {
          const result = {
            id: j.id,
            // eslint-disable-next-line no-nested-ternary
            addPrice: i.sameAddPrice
              ? i.sameAddPriceValue ? +i.sameAddPriceValue : null
              : j.addPrice ? +j.addPrice : null,
            type: j.type,
          }
          if (j.type === 'SimpleItem') {
            result.name = j.name
            result.isEnabled = j.isEnabled
            result.isVisible = j.isVisible
          }
          return result
        }),
      }))

      this.updateMenuItem([this.$route.params.id, 'extras', extras])

      return this.patchBase('extras', extras)
    },
    async patchExtrasIfValid(refName, showNotification) {
      await this.validateExtras(refName, showNotification)
      if (this.isExtrasValid) {
        const patchExtras = this.patchExtras.bind(this)
        this.requests.push(patchExtras)
      }
    },

    async onBlurExtraName(refName) {
      await this.patchExtrasIfValid(refName, true)
    },
    onClickDeleteExtra(extraId) {
      if (!this.isExtrasValid) {
        return
      }

      this.extras = this.extras.filter(i => i.id !== extraId)
      this.translations = this.translations.map(i => ({ ...i, extras: i.extras.filter(j => j.id !== extraId) }))

      this.requests.push(() => [...this.patchExtras(), ...this.patchTranslations()])
    },

    updateExtraMenuItems(val, extraId) {
      this.extras.find(i => i.id === extraId).items.forEach(i => {
        if (i.type === 'MenuItem' || i.type === 'CombinationMenuItem') {
          const exist = val.find(j => j.id === i.id)
          if (!exist) {
            this.extras.find(j => j.id === extraId).items = this.extras.find(j => j.id === extraId).items
              .filter(j => j.id !== i.id)
          }
        }
      })
      val.forEach(i => {
        const exist = this.extras.find(j => j.id === extraId).items.find(j => j.id === i.id)
        if (!exist) {
          this.extras.find(j => j.id === extraId).items = [
            ...this.extras.find(j => j.id === extraId).items,
            {
              ...i,
              addPrice: '',
            },
          ]
        }
      })
    },
    async onCloseTreeselectExtrasMenuItems() {
      await this.patchExtrasIfValid('voExtras', false)
    },

    onClickAddExtraSimpleItem(extraId) {
      if (this.isAddExtraDisabled) {
        return
      }

      const newSimpleItemId = uuidv4()

      this.extras = this.extras.map(extra => {
        if (extra.id === extraId) {
          return {
            ...extra,
            items: [
              ...extra.items,
              {
                id: newSimpleItemId,
                type: 'SimpleItem',
                name: this.$t('Name'),
                addPrice: '',
                isEnabled: true,
                isVisible: true,
              },
            ],
          }
        }
        return extra
      })

      this.translations = this.translations.map(translation => {
        const extras = translation.extras.map(extra => {
          if (extra.id === extraId) {
            return {
              ...extra,
              items: [
                ...extra.items,
                {
                  id: newSimpleItemId,
                  name: '',
                },
              ],
            }
          }
          return extra
        })
        return { ...translation, extras }
      })

      this.requests.push(() => [...this.patchExtras(), ...this.patchTranslations()])
    },
    async onBlurExtraSimpleItemName(refName) {
      await this.patchExtrasIfValid(refName, true)
    },
    async onBlurExtraItemAddPrice() {
      await this.patchExtrasIfValid('voExtras', true)
    },
    async onInputExtraItemParam(newValue, param, extraId, itemId, refName) {
      this.extras = this.extras.map(extra => {
        if (extra.id === extraId) {
          return {
            ...extra,
            items: extra.items.map(i => (i.id === itemId ? { ...i, [param]: newValue } : i)),
          }
        }
        return extra
      })

      await this.validateExtras(refName, false)
    },
    async onClickExtraItemParam(param, extraId, itemId) {
      this.extras = this.extras.map(extra => {
        if (extra.id === extraId) {
          return {
            ...extra,
            items: extra.items.map(item => (item.id === itemId ? { ...item, [param]: !item[param] } : item)),
          }
        }
        return extra
      })

      await this.patchExtrasIfValid('voExtras', true)
    },
    onClickDeleteExtraItem(extraId, itemId) {
      this.extras = this.extras.map(extra => {
        if (extra.id === extraId) {
          return {
            ...extra,
            items: extra.items.filter(i => i.id !== itemId),
          }
        }
        return extra
      })

      this.translations = this.translations.map(translation => {
        const extras = translation.extras.map(extra => {
          if (extra.id === extraId) {
            return {
              ...extra,
              items: extra.items.filter(i => i.id !== itemId),
            }
          }
          return extra
        })
        return { ...translation, extras }
      })

      this.requests.push(() => [...this.patchExtras(), ...this.patchTranslations()])
    },

    async onChangeExtraSameAddPrice() {
      await this.patchExtrasIfValid('voExtras', true)
    },
    async onClickExtraSameAddPriceValue(extraId) {
      this.extras = this.extras.map(extra => {
        if (extra.id === extraId) {
          return {
            ...extra,
            sameAddPrice: true,
          }
        }
        return extra
      })

      await this.onChangeExtraSameAddPrice()
    },
    async onInputExtraSameAddPriceValue(newValue, extraId) {
      this.extras = this.extras.map(extra => {
        if (extra.id === extraId) {
          return {
            ...extra,
            sameAddPriceValue: newValue,
          }
        }
        return extra
      })

      await this.validateExtras('voExtras', false)
    },
    async onBlurExtraSameAddPriceValue() {
      await this.patchExtrasIfValid('voExtras', true)
    },

    async onClickAddExtra() {
      this.isExtraPosting = true

      const newExtra = {
        id: uuidv4(),
        name: this.$t('Consider these extras'),
        sameAddPrice: true,
        items: [],
      }

      this.extras = [
        ...this.extras,
        {
          // eslint-disable-next-line no-undef
          ...structuredClone(newExtra),
          sameAddPriceValue: null,
        },
      ]

      this.translations = this.translations.map(i => ({
        ...i,
        extras: [
          ...i.extras,
          {
            id: newExtra.id,
            name: '',
            sameAddPrice: true,
            items: [],
          },
        ],
      }))

      await Promise.all([...this.patchExtras(), ...this.patchTranslations()])

      this.$nextTick(() => {
        this.$refs[`extra-name_${newExtra.id}`][0].focus()
      })

      this.isExtraPosting = false
    },
  },
}
