<template>
  <div
    :class="[
      'item-badges-tags',
      { 'item-badges-tags_active': isDropdownShown },
    ]"
    @click="onClickRootElement"
  >
    <b-dropdown
      ref="dropdown"
      no-caret
      lazy
      variant="flat"
      @shown="onShownDropdown"
      @hidden="onHiddenDropdown"
    >
      <template #button-content>
        <feather-icon
          :icon="icon"
          size="18"
        />
        <span class="ml-50 mr-2 font-weight-bolder">{{ title }}</span>
        <small
          v-if="!value.length"
          class="text-secondary"
        >{{ $t('empty') }}</small>
      </template>
      <div @click.stop>
        <h5 class="mb-1">
          {{ title }}
        </h5>
        <validation-provider
          #default="{ errors, failedRules }"
          name="search_or_create"
          :rules="`max:${textLimit}`"
        >
          <b-input-group
            class="input-group-merge"
            size="sm"
          >
            <b-form-input
              ref="input"
              v-model="inputValue"
              :disabled="isCreating"
              :state="value.length >= valueMaxLength || errors.length ? false : null"
              :placeholder="$t('Search or Create')"
              @keyup.enter="onKeyupEnter"
            />
            <b-input-group-append
              v-if="!isCreating"
              v-b-tooltip.hover.top="inputValue ? $t('Accept') : tooltipText"
              is-text
              @click="onClickEnter"
            >
              <feather-icon
                v-if="inputValue"
                icon="CornerDownLeftIcon"
                class="d-flex cursor-pointer"
              />
              <feather-icon
                v-else
                icon="InfoIcon"
                :class="['d-flex text-primary', { 'text-danger': value.length >= valueMaxLength }]"
              />
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger">
            <template v-if="value.length >= valueMaxLength">{{ $t(validationErrorKeyItems) }}</template>
            <template v-else-if="failedRules.max">{{ $t(validationErrorKeyItem, { slot: textLimit }) }}</template>
            <template v-else>{{ errors[0] }}</template>
          </small>
        </validation-provider>
      </div>
      <div
        v-if="itemsToChoose.length"
        class="item-badges-tags__to-choose"
      >
        <b-badge
          v-for="item in itemsToChoose"
          :key="item.id"
          :variant="item.name === inputValue ? 'primary' : 'outline-secondary'"
          @click.stop="onClickItemToChoose(item)"
        >
          {{ item.name }}
          <feather-icon
            icon="XIcon"
            @click.stop="onClickDeleteItem(item.id)"
          />
        </b-badge>
      </div>
    </b-dropdown>
    <draggable
      :list="value"
      tag="div"
      class="badges"
      @end="onEndDrag"
    >
      <b-badge
        v-for="item in value"
        :key="item.id"
        :variant="item.name === inputValue ? 'primary' : badgeSelectedVariant"
        @click.stop
      >
        {{ item.name }}
        <feather-icon
          icon="XIcon"
          @click="onClickUnlinkItem(item)"
        />
      </b-badge>
    </draggable>
    <feather-icon
      v-if="isClearButton && value.length"
      v-b-tooltip.hover.top="$t('Clear')"
      icon="XIcon"
      class="ml-auto"
      size="18"
      @click.stop="onClickUnlinkItems"
    />
  </div>
</template>

<script>
import {
  BBadge,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BDropdown,
  VBTooltip,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import { mapGetters, mapActions } from 'vuex'

import { ValidationProvider } from 'vee-validate'
import { max } from '@validations'

export default {
  name: 'ItemBadgesTags',
  components: {
    BBadge,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    draggable,

    // validations
    ValidationProvider,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    icon: {
      type: String,
      required: true,
      default: '',
    },
    title: {
      type: String,
      required: true,
      default: '',
    },
    tooltipText: {
      type: String,
      required: true,
      default: '',
    },
    validationErrorKeyItems: {
      type: String,
      required: true,
      default: '',
    },
    validationErrorKeyItem: {
      type: String,
      required: true,
      default: '',
    },
    menuItemKey: {
      type: String,
      required: true,
      default: '',
    },
    badgeSelectedVariant: {
      type: String,
      required: true,
      default: '',
    },
    isClearButton: {
      type: Boolean,
      required: true,
      default: false,
    },
    valueMaxLength: {
      type: Number,
      required: true,
      default: 5,
    },
    textLimit: {
      type: Number,
      required: true,
      default: 25,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    itemsTranslations: {
      type: Array,
      required: true,
      default: () => [],
    },
    setItems: {
      type: Function,
      required: true,
      default: () => null,
    },
    setItemsTranslations: {
      type: Function,
      required: true,
      default: () => null,
    },
    deleteItem: {
      type: Function,
      required: true,
      default: () => null,
    },
    deleteItemTranslation: {
      type: Function,
      required: true,
      default: () => null,
    },
    createItem: {
      type: Function,
      required: true,
      default: () => null,
    },
    createItemTranslation: {
      type: Function,
      required: true,
      default: () => null,
    },
    value: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      inputValue: '',
      isDropdownShown: false,
      isCreating: false,

      // validation rules
      max,
    }
  },
  computed: {
    ...mapGetters({
      restaurant: 'restaurant/restaurant',
      menuItems: 'menuManagement/menuItems',
    }),
    itemsToChoose() {
      if (this.value.length >= this.valueMaxLength) {
        return []
      }

      return this.items
        .filter(i => !this.value.find(j => j.id === i.id))
        .filter(i => i.name.toLowerCase().includes(this.inputValue.toLowerCase()))
    },

  },
  methods: {
    ...mapActions({
      setMenuItems: 'menuManagement/setMenuItems',
    }),

    getFunctionName(func) {
      return func.name.startsWith('bound ') ? func.name.slice(6) : func.name
    },

    closeDropdownIfLimitReached(valueLength) {
      if (valueLength >= this.valueMaxLength) {
        const refDropdown = this.$refs.dropdown
        if (refDropdown.visible) {
          refDropdown.hide()
        }
      }
    },

    async onClickDeleteItem(id) {
      const itemsTranslationsToDelete = this.itemsTranslations.filter(i => i.originalId === id)

      this.setItems(this.items.filter(i => i.id !== id))
      this.setItemsTranslations(this.itemsTranslations.filter(i => i.originalId !== id))

      this.setMenuItems(
        this.menuItems.map(i => ({
          ...i,
          [this.menuItemKey]: i[this.menuItemKey].filter(j => j.id !== id),
        })),
      )

      await Promise.all([
        this.deleteItem(id)[0],
        ...itemsTranslationsToDelete.map(i => this.deleteItemTranslation(i.language, i.originalId)[0]),
      ])
    },

    onClickRootElement() {
      const refDropdown = this.$refs.dropdown
      if (refDropdown.visible) {
        refDropdown.hide()
      } else {
        refDropdown.show()
      }
    },

    onEndDrag() {
      this.$emit('input', this.value)
    },

    onClickUnlinkItems() {
      this.$emit('input', [])
    },
    onClickUnlinkItem(item) {
      this.$emit('input', this.value.filter(i => i.id !== item.id))
    },

    onClickItemToChoose(item) {
      const valueLengthAfterUpdate = this.value.length + 1
      this.$emit('input', [...this.value, item])
      this.inputValue = ''
      this.closeDropdownIfLimitReached(valueLengthAfterUpdate)
    },

    async handleInputValue() {
      if (this.value.length >= this.valueMaxLength) {
        return
      }

      const value = this.inputValue.trim()
      if (!value || value.length > this.textLimit) {
        return
      }

      const item = this.items.find(i => i.name === value)
      if (item) {
        if (this.value.find(i => i.id === item.id)) {
          this.inputValue = ''
        } else {
          const valueLengthAfterUpdate = this.value.length + 1
          this.$emit('input', [...this.value, item])
          this.inputValue = ''
          this.closeDropdownIfLimitReached(valueLengthAfterUpdate)
        }
      } else {
        this.isCreating = true

        const response = await this.createItem(value)[0]
        const newItem = response.data[this.getFunctionName(this.createItem)]

        const responses = await Promise.all(
          this.restaurant.translationLanguages.map(i => this.createItemTranslation(i, newItem.id, '')[0]),
        )

        this.setItems([...this.items, newItem])
        this.setItemsTranslations([
          ...this.itemsTranslations,
          ...responses.map(i => i.data[this.getFunctionName(this.createItemTranslation)]),
        ])

        const valueLengthAfterUpdate = this.value.length + 1
        this.$emit('input', [...this.value, newItem])
        this.inputValue = ''
        this.closeDropdownIfLimitReached(valueLengthAfterUpdate)

        this.isCreating = false
      }
    },
    onKeyupEnter() {
      this.handleInputValue()
    },
    onClickEnter() {
      this.handleInputValue()
    },

    onShownDropdown() {
      this.$refs.input.focus()
      this.isDropdownShown = true
    },
    onHiddenDropdown() {
      this.isDropdownShown = false
    },
  },
}
</script>

<style lang="sass">
@import '@core/scss/base/bootstrap-extended/_variables.scss'

.item-badges-tags
  display: flex
  align-items: center
  cursor: pointer
  padding: 1rem 1.5rem
  &_active,
  &:hover
    background-color: $body-bg
  &__to-choose
    display: flex
    flex-wrap: wrap
    gap: 5px
    margin-top: 1rem
  .dropdown-toggle
    display: flex
    align-items: center
    border: none
    border-radius: 0
    padding: 3px 0
  .dropdown-menu
    width: 250px
    padding: 1rem
    background-color: $body-bg
    border-color: $gray-300
    cursor: auto
  .form-control
    &.is-invalid
      background-image: none
      & + .input-group-append
        .input-group-text
          border-color: $red
  .badge
    font-size: 14px
    cursor: pointer
    padding: 0.25rem
    border: 1px solid transparent
    &.badge-outline-primary
      border-color: $primary
      color: $primary
      background-color: $white
    &.badge-outline-secondary
      border-color: $secondary
      color: $secondary
      background-color: $white
    .feather
      cursor: pointer
      position: relative
      top: 1px
  .badges
    display: flex
    flex-wrap: wrap
    gap: 5px
    .badge
      cursor: move
</style>
