import { v4 as uuidv4 } from 'uuid'

export default {
  data() {
    return {
      options: [],
      isOptionPosting: false,
      isOptionsValid: [],
      textLimitOption: 100,
      isAddedOptionsItemDefault: false,
    }
  },
  computed: {
    optionsItemsNames() {
      return this.options.map(i => (i.items.length ? i.items[i.items.length - 1].name : ''))
    },
  },
  watch: {
    optionsItemsNames() {
      if (this.isAddedOptionsItemDefault) {
        this.options.forEach(i => {
          if (i.items.length && i.items[i.items.length - 1].name) {
            this.addOptionItem(i.id, true)
          }
        })
      }
    },
  },
  methods: {
    addOptionItem(optionId, isPatchNeeded) {
      const newOptionItemId = uuidv4()

      this.options = this.options.map(option => {
        if (option.id === optionId) {
          return {
            ...option,
            items: [
              ...(option.items.length === 1 ? option.items.map(item => ({ ...item, default: true })) : option.items),
              {
                id: newOptionItemId,
                name: '',
                addPrice: '',
                isVisible: true,
                isEnabled: true,
                default: false,
              },
            ],
          }
        }
        return option
      })

      this.translations = this.translations.map(translation => {
        const options = translation.options.map(option => {
          if (option.id === optionId) {
            return {
              ...option,
              items: [
                ...option.items,
                {
                  id: newOptionItemId,
                  name: '',
                },
              ],
            }
          }
          return option
        })
        return { ...translation, options }
      })

      if (isPatchNeeded) {
        this.requests.push(() => [...this.patchOptions(), ...this.patchTranslations()])
      }
    },

    setOptions(options) {
      this.isAddedOptionsItemDefault = false

      // eslint-disable-next-line no-undef
      this.options = structuredClone(options).map(i => ({
        ...i,
        items: i.items.map(j => ({ ...j, addPrice: this.getFormattedPrice(j.addPrice) })),
      }))

      if (
        (this.options.length && this.$refs['card-options'] && !this.$refs['card-options'].$el.getElementsByClassName('card-content collapse')[0].classList.contains('show'))
        || (!this.options.length && this.$refs['card-options'] && this.$refs['card-options'].$el.getElementsByClassName('card-content collapse')[0].classList.contains('show'))
      ) {
        this.$refs['card-options'].$el.getElementsByClassName('card-header')[0].click()
      }
    },

    async validateOptions(refName, showNotification) {
      this.isOptionsValid = await this.$refs[refName].validate()
      if (!this.isOptionsValid && showNotification) {
        this.showNotificationToFixValidation()
      }
    },
    patchOptions() {
      const options = this.options.map(i => ({
        ...i,
        items: i.items
          .filter((j, jIndex) => !!j.name && jIndex + 1 !== i.items.length)
          .map(j => ({ ...j, addPrice: j.addPrice ? +j.addPrice : null })),
      }))

      this.updateMenuItem([this.$route.params.id, 'options', options])

      return this.patchBase('options', options)
    },
    async patchOptionsIfValid(refName, showNotification) {
      await this.validateOptions(refName, showNotification)
      if (this.isOptionsValid) {
        const patchOptions = this.patchOptions.bind(this)
        this.requests.push(patchOptions)
      }
    },

    async onBlurOptionName(refName) {
      await this.patchOptionsIfValid(refName, true)
    },
    onClickDeleteOption(optionId) {
      if (!this.isOptionsValid) {
        return
      }

      this.options = this.options.filter(i => i.id !== optionId)
      this.translations = this.translations.map(i => ({ ...i, options: i.options.filter(j => j.id !== optionId) }))

      this.requests.push(() => [...this.patchOptions(), ...this.patchTranslations()])
    },

    async onChangeOptionType(option, newType) {
      this.options = this.options.map(i => {
        if (i.id === option.id) {
          let newName = i.name
          if (newName === this.$t('Choose') || newName === this.$t('Add') || newName === this.$t('Remove')) {
            if (newType === 'choose') {
              newName = this.$t('Choose')
            }
            if (newType === 'add') {
              newName = this.$t('Add')
            }
            if (newType === 'remove') {
              newName = this.$t('Remove')
            }
          }

          return {
            ...i,
            name: newName,
          }
        }
        return i
      })

      await this.patchOptionsIfValid('voOptions', true)
    },

    async onBlurOptionItemName(refName) {
      await this.patchOptionsIfValid(refName, true)
    },
    async onChangeOptionItemDefault(optionItemId, optionId) {
      this.options = this.options.map(option => {
        if (option.id === optionId) {
          return {
            ...option,
            items: option.items.map(i => ({ ...i, default: i.id === optionItemId })),
          }
        }
        return option
      })

      await this.patchOptionsIfValid('voOptions', true)
    },
    async onInputOptionItemPrice(newValue, optionId, itemId) {
      this.options = this.options.map(option => {
        if (option.id === optionId) {
          return {
            ...option,
            items: option.items.map(i => (i.id === itemId ? { ...i, addPrice: newValue } : i)),
          }
        }
        return option
      })

      await this.validateOptions('voOptions', false)
    },
    async onBlurOptionItemPrice() {
      await this.patchOptionsIfValid('voOptions', true)
    },
    async onClickOptionItemParam(param, optionId, itemId) {
      this.options = this.options.map(option => {
        if (option.id === optionId) {
          return {
            ...option,
            items: option.items.map(i => (i.id === itemId ? { ...i, [param]: !i[param] } : i)),
          }
        }
        return option
      })

      await this.patchOptionsIfValid('voOptions', true)
    },
    onClickDeleteOptionItem(optionId, optionItemId) {
      this.options = this.options.map(option => {
        if (option.id === optionId) {
          let items = option.items.filter(i => i.id !== optionItemId)

          if (option.items.length > items.length && items.length > 1 && items.every(i => !i.default)) {
            items = items.map((item, itemIndex) => (itemIndex === 0 ? { ...item, default: true } : item))
          }

          return { ...option, items }
        }
        return option
      })

      this.translations = this.translations.map(translation => {
        const options = translation.options.map(option => {
          if (option.id === optionId) {
            return {
              ...option,
              items: option.items.filter(i => i.id !== optionItemId),
            }
          }
          return option
        })
        return { ...translation, options }
      })

      this.requests.push(() => [...this.patchOptions(), ...this.patchTranslations()])
    },

    async onClickAddOption() {
      this.isOptionPosting = true

      const newOption = {
        id: uuidv4(),
        name: this.$t('Choose'),
        type: 'choose',
        items: [],
      }

      this.options = [
        ...this.options,
        // eslint-disable-next-line no-undef
        structuredClone(newOption),
      ]

      this.translations = this.translations.map(translation => ({
        ...translation,
        options: [
          ...translation.options,
          {
            id: newOption.id,
            name: '',
            items: [],
          },
        ],
      }))

      await Promise.all([...this.patchOptions(), ...this.patchTranslations()])

      this.$nextTick(() => {
        this.$refs[`option-name_${newOption.id}`][0].focus()
      })

      this.addOptionItem(newOption.id, false)

      this.isOptionPosting = false
    },
  },
}
